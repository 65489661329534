<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">
          <span v-if="!!currentNotificationId">Редагувати правило повідомлення</span>
          <span v-else>Створити правило автоматичного повідомлення</span>
        </div>
      </v-toolbar>

      <NotificationForm v-if="isLoaded" :is-editable="!!currentNotificationId" :currentNotificationId="currentNotificationId" />
    </v-card>
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'PushNotificationSettings',

  components: {
    BackButton,
    NotificationForm: () => import('@/components/support/push_notification/NotificationForm'),
  },

  data: () => ({
    isLoaded: true,
  }),

  computed: {
    currentNotificationId() {
      return this.$route.params.notificationId || null
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.setCurrentPushNotificationSettingsEmpty()
  },

  methods: {
    ...mapActions('pushNotification', ['loadCurrentPushNotificationSettings']),
    ...mapMutations('pushNotification', ['SET_CURRENT_NOTIFICATION_SETTINGS']),

    async initialize() {
      if (this.currentNotificationId) {
        this.isLoaded = false
        await this.loadCurrentPushNotificationSettings(this.currentNotificationId)
        this.isLoaded = true
      }
    },
    setCurrentPushNotificationSettingsEmpty() {
      this.SET_CURRENT_NOTIFICATION_SETTINGS({})
    },
  },
}
</script>

<style scoped></style>
